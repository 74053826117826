
import { defineComponent, ref } from 'vue';
import { Form, Field } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'new-entity-course',
  components: {
    Form,
    Field,
    Datatable,
  },
  data() {
    return {
      batch: {
        entity_id: '' as any,
        tranche_id: '' as any,
        start_date: '' as any,
        end_date: '' as any,
      },
      employee: {},
      banktraineeCount: [],
      totalentity: 0 as any,
      totalInstitute: 0 as any,
      totalbank: 0 as any,
      totalagentbank: 0 as any,
      totalmobile: 0 as any,

      details: {},
      weekplan: {},
      mobileBankingTrainee: [],
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      tranches_info: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tabIndex: ref(0),
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '150px',
        },
        {
          name: 'Name',
          key: 'name',
          sortable: false,
        },
        {
          name: 'Selected Trainee Count',
          key: 'selected_trainee_count',
          sortable: true,
        },
      ],
      entityData: [] as any,
      instituteData: [] as any,
      bankData: [] as any,
      mobilebankData: [] as any,
      agentbankData: [] as any,
      totalSelectedEntity: 0 as any,
      totalSelectedIns: 0 as any,
      totalSelectedBank: 0 as any,
      totalSelectedAgentBank: 0 as any,
      totalSelectedMobileBank: 0 as any,
      tableData: [
        {
          sl: 1,
          name: 'x',
          selected_trainee_count: 200,
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
      load: false,
    };
  },
  async created() {
    await this.getTranches();
    await this.getEntityInfos();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async search() {
      this.getTraineePartners();
    },
    async getTranches() {
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {
          this.tranches_info = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTraineePartners() {
      if (
        this.batch.tranche_id != false &&
        this.batch.start_date != false &&
        this.batch.end_date != false
      ) {
        this.load = true;
        ApiService.get(
          'stipend/selectedtrainee?entity_info_id=' +
            this.batch.entity_id +
            '&tranche_id=' +
            this.batch.tranche_id +
            '&start_date=' +
            this.batch.start_date +
            '&end_date=' +
            this.batch.end_date
        )
          .then((response) => {
            this.entityData = response.data.data.data.entity;

            this.instituteData = response.data.data.data.institue;
            this.bankData = response.data.data.data.bank;
            this.agentbankData = response.data.data.data.agent_bank;
            this.mobilebankData = response.data.data.data.mobile_bank;
            this.totalSelectedEntity = this.entityData.reduce((acc, entity) => {
              return acc + entity.selected;
            }, 0);
            this.totalSelectedIns = this.instituteData.reduce((acc, entity) => {
              return acc + entity.selected;
            }, 0);
            this.totalSelectedBank = this.bankData.reduce((acc, entity) => {
              return acc + entity.selected;
            }, 0);
            this.totalSelectedAgentBank = this.agentbankData.reduce(
              (acc, entity) => {
                return acc + entity.selected;
              },
              0
            );
            this.totalSelectedMobileBank = this.mobilebankData.reduce(
              (acc, entity) => {
                return acc + entity.selected;
              },
              0
            );

            this.showCourseNotice = true;
            this.load = false;
          })
          .catch((response) => {
            this.load = false;
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Please check all the required field',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },
    async getEntityInfos() {
      await ApiService.get('entity/list')
        .then((response) => {
          this.entityInfos = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get('entity/types')
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
    setActiveTab(event) {
      this.tabIndex = parseInt(event.target.getAttribute('data-tab-index'));
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
